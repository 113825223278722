import { createElement as rc, useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';
import { View, Text, Icon, CheckBox, fromTheme, webOnlyStyles, styled } from 'lib_ui-primitives';
import useMultiSelect from '../../../hooks/useMultiSelect';
import getNestedPatchDetailColumnHNodes from '../columns/PatchDetail/getNestedPatchDetailColumnHNodes';
import useDataModel from '../../../hooks/useDataModel';
import { getColumnHNodes, getColumnWidths } from '../columns/useColumnWidths';

let TableHeader = styled(View).attrs({ name: 'TableHeader' })`
    background-color: ${fromTheme('table', 'headerColor')};
    display: flex;
    flex-direction: row;
    border-top-width: 1px;
    border-top-color: ${fromTheme('borderColor')};
    flex-shrink: 0;
    min-width: 100%;
`;
TableHeader = webOnlyStyles(TableHeader)`
    border-top-style: solid;
    box-shadow: ${({ theme }) => `${theme.borderColor} 0 1px 2px`};
    width: fit-content;
`;

let TableHeaderColumn = styled(View)`
    display: flex;
    flex-direction: row;
    min-width: ${({ width }) => width + 'px'};
    justify-content: flex-start;
    align-items: center;
    border-left-width: 0;
    border-left-color: white;
`;
TableHeaderColumn = webOnlyStyles(TableHeaderColumn)`
    width: fit-content;
    border-left-style: solid;

    // Add a border to the right of the column to act as a resize handle
    position: relative;
    border-right: 1px solid;
    border-right-color: ${({ theme }) => theme.columnHeaderResizeHandle};

    // Show the resize handle on hover
    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 5px;
        cursor: col-resize;
    }
`;

let FirstCell = styled(Text)`
    color: ${fromTheme('colorScheme', 'white-text')};
    padding: ${fromTheme('textPadding')};
    margin-left: ${fromTheme('viewMargin')};
    text-align: left;
`;
FirstCell = webOnlyStyles(FirstCell)`
    cursor: ${({ sortable }) => (sortable ? 'pointer' : 'inherit')};
`;

const CheckBoxFirstCell = styled(View)`
    align-items: center;
    padding: 0 ${fromTheme('viewPaddingMore')} 0 ${fromTheme('viewPadding')};
`;
// Add breaks between cells by increasing border-left-width:
// border-left-width: 1px;
// border-left-color: black;
let NotFirstCell = styled(Text)`
    color: ${fromTheme('colorScheme', 'white-text')};
    text-align: left;
    padding: ${fromTheme('textPadding')};
`;
NotFirstCell = webOnlyStyles(NotFirstCell)`
    cursor: ${({ sortable }) => (sortable ? 'pointer' : 'inherit')};
`;

const ArrowIcon = styled(Icon)`
    font-size: 20px;
`;

const noop = () => {};

const _p = {
    useDataModel
};
export const _private = _p;

export default function HeaderRow(props) {
    const {
        hNode,
        hNode: { includeCheckbox, singleRowSelect, namespace, relation },
        headerClicked = noop,
        sortedColumns,
        columnHNodes: _columnHNodes,
        handleColumnWidthChange = noop
    } = props || { hNode: {} };
    const theme = useContext(ThemeContext);

    const columnHNodes = useMemo(() => getColumnHNodes(_columnHNodes, hNode.children), [_columnHNodes, hNode.children]);
    const columnWidths = useMemo(() => getColumnWidths(columnHNodes, theme), [columnHNodes, theme]);

    const { onChangeEverything, invertSelection } = useMultiSelect({ namespace, relation, singleRowSelect });
    const dataDictionary = _p.useDataModel(namespace, relation);

    // prettier-ignore
    return rc(TableHeader, null,
        includeCheckbox && rc(CheckBoxFirstCell, {},
           !singleRowSelect && rc(CheckBox, {
                onClick: onChangeEverything,
                value: invertSelection,
                fontColor: theme.colorScheme['white-text'],
                checkedColor: theme.colorScheme['white-text']
            })
        ),
        columnHNodes.flatMap((c, i) => {
            let columns = [c];
            const width = columnWidths[i];
            if (c.hNodeType === 'PatchDetail') {
                columns = getNestedPatchDetailColumnHNodes(c.propertyName);
            }
            // most of the time this will only return 1 column. just for PatchDetail this will return 4 columns
            return columns.map((c, j) => {
                if (c.hNodeType === 'NestedDetail') {
                    //TODO: use data dictionary to see if this property is array or object
                    let needsArrayIndex = false;
                    if (dataDictionary[c.propertyName]) {
                        const meta = dataDictionary[c.propertyName]._meta;
                        needsArrayIndex = meta.hNodeType === 'EditableList' || meta.overrideDataType === 'array';
                        needsArrayIndex = needsArrayIndex && !c.propertyName.match(/\.\d$/);
                    }
                    let child = c.children[0];
                    //sorting does not work with `[0]`!
                    child.propertyPath = c.propertyName + (needsArrayIndex ? '.0' : '');
                    c = child;
                }
                return rc(TableHeaderColumn, { width: width, key: 'headerColumn' + i + j, onDoubleClick: () => handleColumnWidthChange(c) },
                    c.sortable && sortedColumns[c.id] === 'asc' ? rc(ArrowIcon, { title: 'Ascending' }, 'arrow_upward') : null,
                    c.sortable && sortedColumns[c.id] === 'desc' ? rc(ArrowIcon, { title: 'Descending' }, 'arrow_downward') : null,
                    i === 0 && !includeCheckbox
                        ? rc(FirstCell, { onClick: () => headerClicked(c), sortable: c.sortable === 'true' }, c.label)
                        : rc(NotFirstCell, { onClick: () => headerClicked(c), sortable: c.sortable === 'true' }, c.label),
                );
            });
        })
    );
}
